import React, { useRef, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import GravityFormForm from "gatsby-plugin-gravity-forms";
const axios = require("axios").default;
var CryptoJS = require("crypto-js");

const DonationForm = ({ module }) => {
  const [chkSum, setChkSum] = useState("");
  const [payid, setPayId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputEl = useRef(null);
  const [form, setForm] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
    input_9: "",
    input_10: "",
    input_13: "",
    input_11: "",
    input_6: "",
    input_12: "",
    input_15: "",
    input_16: "",
    input_8: "",
    input_26: "",
    input_27: "",
    input_19: "",
    input_20: "",
    input_21: "",
    input_22: "",
    input_23: "",
    input_24: "",
    input_28_1: "",
  });

  const [formError, setFormError] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
    input_9: "",
    input_10: "",
    input_13: "",
    input_11: "",
    input_6: "",
    input_12: "",
    input_15: "",
    input_16: "",
    input_26: "",
    input_27: "",
    input_19: "",
    input_20: "",
    input_21: "",
    input_22: "",
    input_23: "",
    input_24: "",
    input_28_1: "",
  });

  const query = useStaticQuery(graphql`
    query formQueryDonation {
      wpGfForm(databaseId: { eq: 3 }) {
        ...GravityFormFields
      }
    }
  `);

  const handleSuccess = (data) => {
    if (data.data[0].value === "EFT") {
      setIsLoading(false);
      document.querySelector(".gform_confirmation_message").remove();
      navigate("/donation/donate-thank-you/")
    } else if(data.data[0].value === "Empowers Africa") {
      setIsLoading(false);
      document.querySelector(".gform_confirmation_message").remove();
      navigate("/donation/empowers-africa/")
    } else {
      setIsLoading(true);
    }

    function makeid(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    let param_data = {
      PAYGATE_ID: `${process.env.GATSBY_PAYGATE_ID}`,
      REFERENCE: makeid(10),
      AMOUNT: data.data[6].value * 100,
      CURRENCY: "ZAR",
      RETURN_URL: `${process.env.GATSBY_SITE_URL}`,
      TRANSACTION_DATE: new Date().toISOString(),
      LOCALE: "en-za",
      COUNTRY: "ZAF",
      EMAIL: "",
    };

    const generateMD5 = (
      obj,
      secret = `${process.env.GATSBY_PAYGATE_SECRET}`
    ) => {
      let str = "";
      for (let val in obj) {
        str += obj[val];
      }
      str += secret;
      return CryptoJS.MD5(str).toString();
    };

    const generate_md5 = generateMD5(param_data);
    param_data = { ...param_data, CHECKSUM: generate_md5 };
    param_data = new URLSearchParams(param_data).toString();
    var isRequestFinished = false;

    axios
      .post("https://secure.paygate.co.za/payweb3/initiate.trans", param_data)
      .then((response) => {
        if (data.data[0].value === "Credit Card") {
          const response_data = response.data.split("&");
          const pay_id = response_data[1].split("=");
          const chk_sum_id = response_data[3].split("=");
          setPayId(pay_id[1]);
          setChkSum(chk_sum_id[1]);
          isRequestFinished = true;
        }
      })
      .catch();
    if (data.data[0].value === "Credit Card") {
      document.querySelector(".gform_confirmation_message").remove();
      setInterval(() => {
        if (isRequestFinished) {
          inputEl.current.submit();
        }
      }, 800);
    }

    if (data.data[0].value === "EFT") {
      isRequestFinished = true;
    }
  };


  return (
    <>
      {module.formShortcode == 3 ? (
        <>
          {isLoading && (
            <div className="fixed flex flex-col justify-center items-center top-0 w-full h-full bg-white bg-opacity-90 z-999">
              <h4 className="block text-24 animate-pulse">
                  We really appreciate your donation... now we just need to ask for your patience while we redirect you.
              </h4>
            </div>
          )}
          <div className="footer-newsletter donation-form">
            <div className="container-fluid-md">
              <GravityFormForm data={query} successCallback={handleSuccess} />
            </div>
          </div>
          <div>
            <form
              ref={inputEl}
              action="https://secure.paygate.co.za/payweb3/process.trans"
              id="credit_form"
              method="POST"
            >
              <input type="hidden" name="PAY_REQUEST_ID" value={payid} />
              <input type="hidden" name="CHECKSUM" value={chkSum} />
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="traveller-enquiry-form bg-white rate-form">
            <div className="flex flex-wrap">
              <div className="lg:w-12/12 w-full ">
                <div className="xl:px-80 lg:px-40 px-20 lg:py-40 pb-20 pt-20 relative">
                  <div className="container-fluid-md">
                    <div className="gform_wrapper">
                      <form>
                        <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-2">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Registered Company Name (to reflect on your invoice)
                              <span>*</span>
                            </label>
                            <input
                              type="text"
                              value={form.input_1}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_1: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_1: "",
                                });
                              }}
                            />
                            {formError.input_1 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_1}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Company Trading Name (if different)<span>*</span>
                            </label>
                            <input
                              type="text"
                              value={form.input_2}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_2: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_2: "",
                                });
                              }}
                            />
                            {formError.input_2 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_2}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10 mdscreen:col-span-1 col-span-2">
                            <label>
                              VAT Number (South African businesses only)
                            </label>
                            <input
                              type="number"
                              min={0}
                              value={form.input_3}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_3: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <h5 className="gform_body mt-40">Billing Address</h5>
                        <div className="gform_body">
                          <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-3">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label>
                                Street Address<span>*</span>
                              </label>
                              <input
                                type="text"
                                value={form.input_9}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_9: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_9: "",
                                  });
                                }}
                              />
                              {formError.input_9 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_9}
                                </div>
                              )}
                            </div>
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label>
                                Town/City<span>*</span>
                              </label>
                              <input
                                value={form.input_10}
                                type="text"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_10: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_10: "",
                                  });
                                }}
                              />
                              {formError.input_10 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_10}
                                </div>
                              )}
                            </div>
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label>
                                State/Province<span>*</span>
                              </label>
                              <input
                                value={form.input_13}
                                type="text"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_13: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_13: "",
                                  });
                                }}
                              />
                              {formError.input_13 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_13}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-2">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label>
                                ZIP Code<span>*</span>
                              </label>
                              <input
                                value={form.input_11}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_11: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_11: "",
                                  });
                                }}
                              />
                              {formError.input_11 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_11}
                                </div>
                              )}
                            </div>
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label>
                                Country<span>*</span>
                              </label>
                              <select
                                value={form.input_12}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_12: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_12: "",
                                  });
                                }}
                              >
                                <option value="">Select Country</option>
                                <option value="Åland Islands">Åland Islands</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">
                                  Antigua and Barbuda
                                </option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire, Sint Eustatius and Saba">
                                  Bonaire, Sint Eustatius and Saba
                                </option>
                                <option value="Bosnia and Herzegovina">
                                  Bosnia and Herzegovina
                                </option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">
                                  British Indian Ocean Territory
                                </option>
                                <option value="Brunei Darussalam">
                                  Brunei Darussalam
                                </option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">
                                  Central African Republic
                                </option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">
                                  Christmas Island
                                </option>
                                <option value="Cocos Islands">Cocos Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo, Democratic Republic of the">
                                  Congo, Democratic Republic of the
                                </option>
                                <option value="Congo, Republic of the">
                                  Congo, Republic of the
                                </option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaçao">Curaçao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">
                                  Equatorial Guinea
                                </option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Eswatini (Swaziland)">
                                  Eswatini (Swaziland)
                                </option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">
                                  Falkland Islands
                                </option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">
                                  French Polynesia
                                </option>
                                <option value="French Southern Territories">
                                  French Southern Territories
                                </option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard and McDonald Islands">
                                  Heard and McDonald Islands
                                </option>
                                <option value="Holy See">Holy See</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">
                                  Lao People's Democratic Republic
                                </option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">
                                  Marshall Islands
                                </option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="North Korea">North Korea</option>
                                <option value="Northern Mariana Islands">
                                  Northern Mariana Islands
                                </option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestine, State of">
                                  Palestine, State of
                                </option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">
                                  Papua New Guinea
                                </option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Réunion">Réunion</option>
                                <option value="Saint Barthélemy">
                                  Saint Barthélemy
                                </option>
                                <option value="Saint Helena">Saint Helena</option>
                                <option value="Saint Kitts and Nevis">
                                  Saint Kitts and Nevis
                                </option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Martin">Saint Martin</option>
                                <option value="Saint Pierre and Miquelon">
                                  Saint Pierre and Miquelon
                                </option>
                                <option value="Saint Vincent and the Grenadines">
                                  Saint Vincent and the Grenadines
                                </option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">
                                  Sao Tome and Principe
                                </option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Sint Maarten">Sint Maarten</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">
                                  Solomon Islands
                                </option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia">South Georgia</option>
                                <option value="South Korea">South Korea</option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen Islands">
                                  Svalbard and Jan Mayen Islands
                                </option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">
                                  Trinidad and Tobago
                                </option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">
                                  Turks and Caicos Islands
                                </option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="US Minor Outlying Islands">
                                  US Minor Outlying Islands
                                </option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">
                                  United Arab Emirates
                                </option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Islands, British">
                                  Virgin Islands, British
                                </option>
                                <option value="Virgin Islands, U.S.">
                                  Virgin Islands, U.S.
                                </option>
                                <option value="Wallis and Futuna">
                                  Wallis and Futuna
                                </option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                              {formError.input_12 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_12}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <h5 className="gform_body mt-40">Personal Information</h5>
                        <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-2">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Website Address<span>*</span>
                            </label>
                            <input
                              value={form.input_15}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_15: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_15: "",
                                });
                              }}
                            />
                            {formError.input_15 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_15}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Name of Owner/Director<span>*</span>
                            </label>
                            <input
                              value={form.input_16}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_16: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_16: "",
                                });
                              }}
                            />
                            {formError.input_16 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_16}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              First Name<span>*</span>
                            </label>
                            <input
                              value={form.input_6}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_6: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_6: "",
                                });
                              }}
                            />
                            {formError.input_6 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_6}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Last Name<span>*</span>
                            </label>
                            <input
                              value={form.input_8}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_8: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_8: "",
                                });
                              }}
                            />
                            {formError.input_8 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_8}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Email Address<span>*</span>
                            </label>
                            <input
                              type="email"
                              value={form.input_26}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_26: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_26: "",
                                });
                              }}
                            />
                            {formError.input_26 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_26}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Phone Number<span>*</span>
                            </label>
                            <input
                              type="text"
                              value={form.input_27}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_27: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_27: "",
                                });
                              }}
                            />
                            {formError.input_27 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_27}
                              </div>
                            )}
                          </div>
                        </div>

                        <h5 className="gform_body mt-40">Additional Information</h5>
                        <div className="gap-2 grid mdscreen:grid-cols-1 grid-cols-2">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label className="!mb-[22px] mdscreen:!mb-5">
                              Main target markets by country?<span>*</span>
                            </label>
                            <input
                              value={form.input_19}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_19: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_19: "",
                                });
                              }}
                            />
                            {formError.input_19 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_19}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Kindly advise if you are a Business to Consumer (B2C)
                              agency or Business to Business (B2B) wholesale operator?
                              <span>*</span>
                            </label>
                            <select
                              value={form.input_20}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_20: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_20: "",
                                });
                              }}
                            >
                              <option value="">Select option</option>
                              <option value="B2C">B2C</option>
                              <option value="B2B">B2B</option>
                              <option value="Both">Both</option>
                            </select>
                            {formError.input_20 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_20}
                              </div>
                            )}
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                            Travel Association/Consortia
                            </label>
                            <input
                              value={form.input_21}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_21: e.target.value,
                                });
                              }}
                            >
                            </input>
                          </div>
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label>
                              Do you have an IATA number?
                            </label>
                            <select
                              value={form.input_22}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_22: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select option</option>
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </select>
                          </div>
                          {form.input_22 === "Yes" && <div className="form-group flex flex-wrap lg:mt-30 mt-10 mdscreen:col-span-1 col-span-2">
                            <label>
                              IATA Number<span>*</span>
                            </label>
                            <input
                              type="number"
                              min={0}
                              value={form.input_23}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_23: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_23: "",
                                });
                              }}
                            />
                            {formError.input_23 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_23}
                              </div>
                            )}
                          </div>}
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10 mdscreen:col-span-1 col-span-2">
                            <label>
                              Will you be making payment directly to us?<span>*</span>
                            </label>
                            <select
                              value={form.input_24}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_24: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_24: "",
                                });
                              }}
                            >
                              <option value="">
                                Select option
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {formError.input_24 && (
                              <div className="gravityform__error_message gfield_description validation_message">
                                {formError.input_24}
                              </div>
                            )}
                          </div>
                          <div className="form-group text-area flex flex-wrap col-span-2">
                            <div className="radio-bx checkbox-bx">
                              <input
                                type="checkbox"
                                value="Yes"
                                className="!relative"
                                checked={form.input_28_1 === 'Yes'}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setForm({
                                    ...form,
                                    input_28_1: checked ? 'Yes' : '',
                                  });
                                  setFormError({
                                    ...formError,
                                    input_28_1: "",
                                  });
                                }}
                                id="input_28_1"
                              />
                              <label
                                htmlFor="input_28_1"
                                className="text-dark text-16 !pb-5"
                              >
                                I agree to receive news, rates information, and updates and consent to my submitted data being collected and stored*
                              </label>
                              {formError.input_28_1 && (
                                <div className="gravityform__error_message gfield_description validation_message">
                                  {formError.input_28_1}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="text-center inline-block mt-20 w-full">
                            <button className="button button-gray cursor-pointer" type="button" onClick={() => {
                              var validRegex =
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                              setFormError({
                                ...formError,
                                input_1:
                                  form.input_1 === ""
                                    ? "This field is required."
                                    : "",
                                input_2:
                                  form.input_2 === ""
                                    ? "This field is required."
                                    : "",
                                input_9:
                                  form.input_9 === ""
                                    ? "This field is required."
                                    : "",
                                input_10:
                                  form.input_10 === ""
                                    ? "This field is required."
                                    : "",
                                input_13:
                                  form.input_13 === ""
                                    ? "This field is required."
                                    : "",
                                input_11:
                                  form.input_11 === ""
                                    ? "This field is required."
                                    : "",
                                input_12:
                                  form.input_12 === ""
                                    ? "This field is required."
                                    : "",
                                input_15:
                                  form.input_15 === ""
                                    ? "This field is required."
                                    : "",
                                input_16:
                                  form.input_16 === ""
                                    ? "This field is required."
                                    : "",
                                input_6:
                                  form.input_6 === ""
                                    ? "This field is required."
                                    : "",
                                input_8:
                                  form.input_8 === ""
                                    ? "This field is required."
                                    : "",
                                input_26:
                                  (form.input_26 === "")
                                    ? "This field is required."
                                    : "",
                                input_26:
                                  !form.input_26.match(validRegex)
                                    ? "Enter valid email address."
                                    : "",
                                input_27:
                                  !form.input_26.match(validRegex)
                                    ? "This field is required."
                                    : "",
                                input_19:
                                  form.input_19 === ""
                                    ? "This field is required."
                                    : "",
                                input_20:
                                  form.input_20 === ""
                                    ? "This field is required."
                                    : "",
                                input_22:
                                  form.input_22 === ""
                                    ? "This field is required."
                                    : "",
                                input_23:
                                  (form.input_23 === "" && form.input_22 === "Yes")
                                    ? "This field is required."
                                    : "",
                                input_24:
                                  form.input_24 === ""
                                    ? "This field is required."
                                    : "",
                                    input_28_1:
                                  form.input_28_1 === ""
                                    ? "This field is required."
                                    : "",



                              });
                              if (
                                form.input_1 !== "" &&
                                form.input_2 !== "" &&
                                form.input_9 !== "" &&
                                form.input_10 !== "" &&
                                form.input_13 !== "" &&
                                form.input_11 !== "" &&
                                form.input_12 !== "" &&
                                form.input_15 !== "" &&
                                form.input_16 !== "" &&
                                form.input_8 !== "" &&
                                form.input_19 !== "" &&
                                form.input_20 !== "" &&
                                form.input_24 !== "" &&
                                form.input_6 !== "" &&
                                form.input_26 !== "" &&
                                form.input_27 !== "" &&
                                form.input_28_1 !== ""
                              ) {

                                const requestOptions = {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(form),
                                };
                                const url = `${process.env.GATSBY_WP_URL}wp-json/gf/v2/forms/${module.formShortcode}/submissions`;
                                fetch(url, requestOptions).then((response) => {
                                  if (response.status === 200) {
                                    navigate("/thank-you-rates/");
                                  } else {
                                    setFormError({
                                      ...formError,
                                      input_1: "Please try again.",
                                    });
                                  }
                                });
                              }
                            }}>Submit</button>
                          </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DonationForm;
export const DonationFormFragment = graphql`
  fragment DonationFormFragment on WpPage_Flexiblecontent_FlexibleContent_DonationForm {
    # content
    fieldGroupName
    formShortcode
  }
`;